import React, { useState } from "react";

import { Authentication, DialogConfirmation, User } from "Interfaces/User";
import { Props, Service } from "Interfaces/Service";
import UserContextP, { UserContextModel } from "Interfaces/Context";
import { login } from "Services/Authentication";
import { Bill } from "Interfaces/Bills";

const UserContextProvider: React.FC<Props>  = (props) => {

    //Informacion base del usuario
    const [user, setUser] = useState<User>({
        id: "",
        name: "",
        email: "",
        lastName: "",
        vendorCode: "",
        salesPersonCode: 0,
        priceList:0, 
        branchOffice: 0,
        role: "", 
        codeCellar: "",
        phone: "",
        creationDate: "",
        lastLogin: "",
        documentUser: "",
        device: "",
        vendorName: "",
        buyerCode: ""
    });

    //Informacion de autenticacion
    const [authenticate, setAuth] = useState<Authentication>({
        auth: false,
        uid: ''
    })
    const [clientInformation, setClientInformation] = useState<any>({})

    //Informacion sobre estados de ventanas emergentes
    const [service, setService] = useState<Service>({
        createAccountComercial: false,
        router: {}
    })

    //Informacion de la factura
    const [bill, setBill] = useState<Bill>({
        cardCode:"",
        cardFName:"",
        u_type:"",
        giro: {
            id: "",
            label: ""
        },
        priceList:0,
        nit:"",
        email :"",
        phoneNumber: "",
        nrc:"",
        extraDays:0,
        PymntGroup:"",
        exentIVA:false,
        salesPersonCode:0,
        cardName:"", 
        typeDocument:"", 
        documentNumber:0, 
        documentDate:"", 
        documentDateConta:"", 
        documentDateExpiration: "",
        typePayment:"",
        dateExpired:"",
        timeExpired:0,
        municipality: {
            id: "",
            label: ""
        },
        departament: {
            id: "",
            label: ""
        },
        nameEmployee:"",  
        products:[],
        detailBill:{},
        notes:"",
        WTLiable: ""
    })

    //Copia de la informacion
    const [dataCopy, setDataCopy]= useState<any>()
    
    //Ventanas emergentes
    const [dialogConfirmation, setDialogConfirmation]= useState<DialogConfirmation>({
        open:false,
        answer:false
    })

    const [clientList, setClientList] = useState<any>([])

    //Assigna un usuario
    const assignUser = (newUser: User) => {
        
        setUser(newUser)
    }


    //Asigna el estado del usuario
    const assignateAuth = (uid: string, authenticate: boolean) => {
        let authContent: Authentication = {
            auth: authenticate,
            uid: uid
        }
        setAuth(authContent)
    }

    //Copiado de la informacion
    const assignateCopyData = ( data:any ) =>{
        setDataCopy(data)
    }

    //Logea el usuario
    const loginUser = async (email: string, password: string) => {
        return await login(email, password)
    }

    //Asigna la informacion del cliente
    const setClient = (data: any) => {
        setClientInformation(data)
    }
    
    //Asigna los valores a la cuenta de servicio
    const assignateService = (createAccountComercial: boolean) => {
        let serviceInfo = service;
        service.createAccountComercial = createAccountComercial;
        setService({ ...serviceInfo })
    }

    //Asigna los enlaces para los microservicios
    const assignateRouter = (enlace: any) => {
        let serviceInfo = service;
        serviceInfo.router = enlace;
        setService(serviceInfo)

    }

    //Asigna los productos de la factura
    const assignateBill = ( data : Bill )=> setBill(data)

    const assignateDetailBill = ( data: any ) =>{
        let dataBill = bill
        dataBill.detailBill = data
        setBill(dataBill)
    }

    //Asigna los productos a la factura
    const assiganteProdcutsBills = ( products : any[] )=>{
        let info:Bill = bill
        info.products = products
        setBill(info)
    }

    //Le asgina la fecha al documento
    const assignateDateDocument = ( data : string ) => {
        let info = bill
        info.documentDate = data
        setBill(info)
    }

    //Asigna la fecha de vencimiento
    const assignateDateExpired = ( data : number ) => {
        let date = new Date( bill.dateExpired )
        date.setDate(date.getDate()+ data)
        let dateMask = `${('0' + (date.getDate())).slice(-2)}-${('0' + (date.getMonth() + 1)).slice(-2)}-${date.getFullYear()}`;
        setBill({
            ...bill,
            documentDateExpiration: dateMask
        })
    
    }

    //Asigna la lista de clientes
    const assignateClientList = ( data: any[])=> setClientList(data)

    //Metodo para mostrar la ventana emergente
    const assignateOpen = (open:boolean)=>{
        setDialogConfirmation({
            open: open,
            answer: dialogConfirmation.answer
        })
    }

    //Respuesta de la ventana emergente
    const assignateAnswer = (answer:boolean)=>{
        setDialogConfirmation({
            open:  dialogConfirmation.answer,
            answer: answer
        })
    }

    //Crea el contexto en forma de etiqueta
    const usercontext: UserContextModel = {
        //Informacio
        user,
        assignUser,
        service,
        clientList,
        clientInformation,

        //Ventanas emergentes
        dialogConfirmation,
        assignateOpen,
        assignateAnswer,
        setClient,
        //Factura
        bill,
        assiganteProdcutsBills,
        assignateBill,
        assignateDateDocument,
        assignateDateExpired,
        assignateDetailBill,
        
        //Copia de la informacion
        dataCopy,
        assignateCopyData,
        
        //Lista de clientes
        assignateClientList,
        //Metodos
        assignateService,
        loginUser,
        authenticate,
        assignateRouter,
        assignateAuth,
    }

    return (
        <UserContextP.Provider value={usercontext} >
            {props.children }
        </UserContextP.Provider>
    );
};

export default UserContextProvider;